<template>
	<PopupLayer
		v-if="is_popup"
	>
		<template
			v-slot:body
		>
			<div class="pa-10 overflow-y-auto">
				<div class="justify-space-between pa-10 bg-white top-line">
					<label>
						<input v-model="is_hide" type="checkbox" /> 오늘 하루 안보기
					</label>
					<button
						@click="offPopup"
					><v-icon name="close-circle">mdi-close-circle</v-icon></button>
				</div>
				<img :src="item.popup_img" class="width-100"/>
			</div>
		</template>
	</PopupLayer>

</template>

<script>
import PopupLayer from "@/view/Layout/PopupLayer";
export default {
	name: 'popup'
	,
	components: {PopupLayer},
	props: ['user', 'codes']
	, data: function(){
		return{
			items: []
			, item: {}
			, popup_index: 0
			, is_move: false
			, is_popup: false
			, is_hide: false
		}
	}
	, computed: {

	}
	, methods: {
		getData: async function(){
			try{
				const result = await this.$request.init({
					method: 'get'
					,url: 'main/getMainPopup'
					,data: {
					}
				})

				if(result.success){
					this.item = result.data
					if(this.item.uid){
						let t = localStorage.getItem('popup_' + this.item.uid + '_')
						if(!t){
							this.is_popup = true
						}else{
							let today = this.$date.getToday('-')
							if(t != today){
								this.is_popup = true
							}
						}
					}
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}
		, offPopup: function(){
			if(this.is_hide){
				let today = this.$date.getToday('-')
				localStorage.setItem('popup_' + this.item.uid + '_', today)
			}
			this.is_popup = false
		}
	}
	, created() {
		this.getData()
	}
}
</script>

<style>

@media (max-width: 720px) {
	.box-main-popup {
		width: 100%; height: 120px;
		overflow: hidden;
		white-space: nowrap;
	}
	.box-main-popup > ul  {
		transition: left 0.5s;
	}

	.box-main-popup li {
		width: 100%;
		height: 180px;
	}

	.popup-bg-img {
		background-position: center center; background-size: cover; width: 100%; height: 120px;
	}

	.arrow-right {
		top: 0; right: -10.5px
	}
	.arrow-left {
		top: 0; left: -12.5px; z-index: 1;
	}
}
@media (min-width: 720px) {
	.box-main-popup {
		width: 100%; height: 400px;
		overflow: hidden;
		white-space: nowrap;
	}
	.box-main-popup > ul  {
		transition: left 0.5s;
	}

	.box-main-popup li {
		width: 100%;
		height: 400px;
	}

	.popup-bg-img {
		background-position: center center; background-size: cover; width: 100%; height: 400px;
	}


	.arrow-right {
		top: 0; right: 0
	}
	.arrow-left {
		top: 0; left: 0; z-index: 1;
	}
}

.popup-on {
	left: 0
}
.popup-off {
	left: -100%
}
</style>