<template>
	<div
		class="side"
	>
		<div
			class="bg-side"
			@click="toggleSide"
		></div>

		<div
			class="side-container flex-column pa-20"
		>
			<div
				class="logo-position  justify-space-between items-center"
			>
				<div><img :src="require(`@/assets/images${$skin.img}logo_top.png`)" class="logo-top" /></div>

				<button @click="toggleSide"><v-icon>mdi-close</v-icon></button>
			</div>

			<div class="mt-20 justify-start gap-10">
				<button
					v-if="!user || !user.member_id"
					class="text-12 font-weight-500 btn-round btn-outline btn-ss"
					@click="toLogin"
				>로그인</button>

				<button
					v-if="!user || !user.member_id"
					class="text-12 font-weight-500 btn-round btn-outline btn-ss"
					@click="toJoin"
				>회원가입</button>
				<button
					v-if="user && user.member_id"
					class="text-12 font-weight-500 btn-round btn-outline btn-ss"
					@click="toCart"
				>장바구니</button>
				<button
					v-if="user && user.member_id"
					class="text-12 font-weight-500 btn-round btn-outline btn-ss"
					@click="toMypage"
				>마이페이지</button>
			</div>

			<hr class="top-line-primary-m mt-10" />

			<ul
				v-if="items.length > 0"
				class="side-content overflow-y-auto"
			>
				<template
					v-for="item in items"
				>
					<li
						v-if="!item.not_view"
						:key="'category_' + item.type"
					>
						<div
							class=" justify-space-between items-center under-line ptb-10"
							@click="onCategory(item)"
						>
						<span class="font-weight-bold">{{ item.name }}</span>
						<v-icon
							v-if="item.is_on"
						>mdi mdi-chevron-up</v-icon>
						<v-icon
							v-else
						>mdi mdi-chevron-down</v-icon>

						</div>
						<div
							v-if="item.is_on && item.type != 'point'"
						>
							<template
								v-for="(category) in category"
							>
								<div
									v-if="category.type == item.type"
									:key="'category_' + category.category_code"
									class="mt-10 pb-10 prl-10 justify-space-between items-center under-line"

									@click="toList(category)"
								>
									<span>{{ category.category_name }}</span>
								</div>
							</template>
						</div>
						<div
							v-if="item.is_on && item.type == 'point'"
						>
							<template
							>
								<div
									v-for="(point) in item.items"
									:key="'point_' + point.value"
									class="mt-10 pb-10 pl-10 justify-space-between items-center under-line"

									@click="toPoint(point)"
								>
									<span>{{ point.name }}</span>
								</div>
							</template>
						</div>
					</li>
				</template>
				<li>

					<div
						class=" justify-space-between items-center under-line ptb-10"
						@click="toNotice"
					>
						<span class="font-weight-bold">고객 센터</span>
					</div>
				</li>
			</ul>

			<div class="mt-30 text-center">
				<button
					v-if="user && user.member_id"
					class="btn-round btn-outline btn-ss"
					@click="toLogout"
				>로그아웃</button>
			</div>

			<div
				v-if="false"
				class="mt-auto"
			>
				<div class="pa-10">
					<dl>
						<dt>상호명:</dt> <dd>주식회사 상생홀딩스</dd> <br/>
						<dt>대표자:</dt> <dd>정재철</dd> <br/>
						<dt>주소:</dt> <dd>대구시 동구 동부로26길 33, 3층 3호</dd><br/>
						<dt>사업자 번호:</dt> <dd>711-88-01596</dd><br/>
						<dt>통신판매번호:</dt> <dd>제2019-대구동구-0981호</dd><br/>
						<dt>의료기기판매신고번호:</dt> <dd>제2020-3420023-00065호</dd><br/>
						<dt>이메일:</dt>  <dd>ssholds@naver.com</dd><br/>
						<dt>전화번호:</dt> <dd>1833-6447</dd>
					</dl>
				</div>
				<div
					class="text-center bg-gray pa-10"
				>
					Ver 1.0.0
				</div>
				<div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	export default{
		name: 'Side'
		,props: ['user', 'shop_info', 'category']
		,data: function(){
			return {
				items: [
					{ name: '포인트 충전', type: 'point', items: [
							{ name: '3만원', value: 3}
							, { name: '5만원', value: 5}
							, { name: '10만원', value: 10}
						]
						, not_view: this.user.member_level > 0 ? false : true
					}
					, { name: '모바일 상품몰', type: 'mobile', items: [], not_view: this.user.member_level < 1 ? true : false}
					, { name: '기타 상품몰', type: 'ticket', items: []}
					, { name: '일반 상품몰', type: '', items: []}
					, { name: '기업 상품몰', type: 'business', items: [], not_view: this.user.member_level < 1? true : false}
				]
			}
		}
		,computed: {

		}
		,methods: {
			toItem: function(category){
				this.toggleSide()

				this.$storage.setQuery({ pdt_category: category})
				this.$bus.$emit('to', { name: 'ProductList', params: { category: category} })
			}
			,toggleSide: function(){
				this.$emit('toggleSide')
			}
			,logout: function(){
				let auto_login = localStorage.getItem(process.env.VUE_APP_NAME + 'A')

				if(auto_login) {

					if(confirm('로그아웃시 자동로그인도 해제됩니다. 로그아웃 하시겠습니까?')) {

						localStorage.removeItem(process.env.VUE_APP_NAME + 'A')
						localStorage.removeItem(process.env.VUE_APP_NAME + 'N')
						localStorage.removeItem(process.env.VUE_APP_NAME + 'P')

						sessionStorage.removeItem(process.env.VUE_APP_NAME + 'T')
						sessionStorage.removeItem(process.env.VUE_APP_NAME + 'T2')
						document.location.href= '/Login'
					}

				}else{

					if(confirm('로그아웃 하시겠습니까?')){
						sessionStorage.removeItem(process.env.VUE_APP_NAME + 'T')
						sessionStorage.removeItem(process.env.VUE_APP_NAME + 'T2')
						document.location.href= '/Login'
					}
				}
			}
			, onCategory: function(item){
				this.$set(item, 'is_on', !item.is_on)
			}
			, toNotice: function(){
				this.$bus.$emit('to', { name: 'CustomerCenter', params: {b_code: 'b_notice'}})
			}
			, toPoint: function(point){
				this.$bus.$emit('to', {name: 'PointItem', query: {point: point.value}})
			}
			, toList: function(pdt_div){
				if(pdt_div.type){
					this.$bus.$emit('to', {name: 'ProductList', params: { pdt_div: pdt_div.type}, query: { pdt_category: pdt_div.category_code}})
				}else{
					this.$bus.$emit('to', {name: 'ProductList', query: { pdt_category: pdt_div.category_code}})
				}
			}

			, toLogin: function(){
				this.$bus.$emit('to', { name: 'Login'})
			}
			, toJoin: function(){
				this.$bus.$emit('to', { name: 'Join'})
			}
			, toMypage: function(){
				this.$bus.$emit('to', { name: 'MyPage'})
			}
			, toBoard: function(b_code){
				this.$bus.$emit('to', { name: 'CustomerCenter', params: { b_code: b_code }})
			}
			, toShopping: function(){
				this.$bus.$emit('to', { name: 'OrderList', params: { type: 'all'}})
			}
			, toCart: function(){
				this.$bus.$emit('to', { name: 'Cart'})
			}
			, toLogout: function(){
				if(confirm("로그아웃 하시겠습니까?")){
					this.$bus.$emit('logout')
				}
			}
		}
		,created: function(){
		}
	}
</script>

<style>

	.side {
		width: 100%; height: 100%;
		position: fixed; left: 0; top: 0; z-index: 98;
	}
	.bg-side {
		width: 100%; height: 100%;
		position: fixed; left: 0; top: 0; z-index: 98;
		background-color: gray;
		opacity: 0.5;
	}
	.side-container{
		width: 80%; height: 100%;
		max-width: 300px;
		position: relative;  z-index: 99;
		background-color: white;
		overflow-y: auto;
	}

	.side-container {

	}

	.li-side-content {
		padding: 10px 0;
		border-bottom: 1px solid #ddd;
	}

	.li-side-depth-0 span {
		font-weight: bold;
	}
	.li-side-depth-1 {
		text-indent: 10px;
	}
	.li-side-depth-2 {
		text-indent: 20px;
	}
	.li-side-depth-3 {
		text-indent: 20px;
	}
	.li-side-depth-4 {
		text-indent: 30px;
	}

	dt, dd { display: inline-block}
</style>